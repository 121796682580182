<template>
  <div class="card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Edit Salon</h1>
    <div>
      <FormKit
        v-model="data"
        :type="uploadImage"
        name="image"
        label="Main Banner"
        validation="required"
        validation-visibility="dirty"
        :classes="defaultFormikClass"
        :src="salon.image"
        :upload="updateServiceImage"
      />
      <Form @submit="updateSalon" :initial-data="salon" />
    </div>
  </div>
  <BankAccountList :salon-name="salon.name" />
  <BannerList :salon-id="route.params.id || salonId" />
</template>

<script setup>
import BankAccountList from "./bank-accounts/BankAccountList";
import BannerList from "./banners/BannerList";
import Form from "./Form";
import { useSalonForm } from "@/composables/salon";
import { useRoute, useRouter } from "vue-router";
import { onBeforeMount, ref } from "vue";
import { useAuthRole } from "@/composables/auth";
import { createInput } from "@formkit/vue";
import FormUploadImage from "@/components/form/FormUploadImage";
import { defaultFormikClass } from "@/constants/formik";

const { salon, getDetailSalon, update } = useSalonForm();
const { salonId, isAdminSalon } = useAuthRole();
const router = useRouter();
const route = useRoute();

const data = ref(null);
const uploadImage = createInput(FormUploadImage, {
  props: ["src", "upload"],
});

const { uploadMainBanner } = useSalonForm();

const updateServiceImage = async (data) => {
  await uploadMainBanner(route.params.id, data);
  await getDetailSalon(route.params.id || salonId.value);
};

const updateSalon = async (data) => {
  await update(route.params.id || salonId.value, data);
  await router.push({
    name: isAdminSalon() ? "salon" : "detail-salon",
    params: isAdminSalon() ? null : {id: route.params.id}
  });
};

onBeforeMount(async () => {
  await getDetailSalon(route.params.id || salonId.value);
});
</script>